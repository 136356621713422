import { graphql } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import gradientBg from "../images/OrdersConsolidation/gradient-pink-bg.png";
import iconAdv1 from "../images/OrdersConsolidation/icon-adv-1.svg";
import iconAdv2 from "../images/OrdersConsolidation/icon-adv-2.svg";
import iconAdv3 from "../images/OrdersConsolidation/icon-adv-3.svg";
import Faq from "../components/faq/faq";
import Features from "../components/features/features";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ImageText from "../components/imageText/imageText";
import Integrations from "../components/integrations/integrations";
import News from "../components/news/news";
import Seo from "../components/seo";
import Testimonials from "../components/testimonials/testimonials";
import Title from "../components/title/title";
import Top from "../components/top/top";

import testimonialsState from "./../utils/testimonials";

// markup
const OrdersConsolidationPage = ({ location, data }) => {
  const scrollToForm = "#";

  const topProps = {
    layout: 2,
    title: (
      <>
        Keep track of all your online orders with
        <br className="d-none d-lg-inline-block" /> one device
      </>
    ),
    subtitle: ``,
    scrollToForm: scrollToForm,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        // alt="online ordering tablet"
        // src="../images/OrdersConsolidation/orders-online.png"
        alt="online ordering tablet"
        src="../images/OrdersConsolidation/Orders-2.png"
        className="mw-100"
      />
    ),
  };

  const features = [
    {
      bg: "#F1F7FF",
      icon: iconAdv1,
      title: (
        <span style={{ fontSize: "28.3px", lineHeight: "42px" }}>
          One device for all
          <br /> orders
        </span>
      ),
      description: (
        <p style={{ fontSize: "18px" }}>
          Take back your counter space and save employee time by receiving all
          your orders into one device!
        </p>
      ),
    },
    {
      bg: "#DEFFEE",
      icon: iconAdv2,
      title: (
        <span style={{ fontSize: "28.3px", lineHeight: "42px" }}>
          Universal
          <br /> Printing
        </span>
      ),
      description: (
        <p style={{ fontSize: "18px" }}>
          Orders.co makes ticket and receipt printing easier than ever before
          with an integrated printer for kitchen tickets and customer receipts.
        </p>
      ),
    },
    {
      bg: "#FFF7E3",
      icon: iconAdv3,
      title: (
        <span style={{ fontSize: "25px", lineHeight: "38px" }}>
          Easily add new sales channels
        </span>
      ),
      description: (
        <p style={{ fontSize: "18px" }}>
          Restaurants looking to expand their online ordering presence can have
          new delivery platforms added into their existing Orders.co service in
          just a few hours free of charge!
        </p>
      ),
    },
  ];

  const testimonialsProps = {
    layout: 2,
    items: [
      testimonialsState.c_viche_large,
    ],
    title: (
      <>
        Hear From
        <br /> Our Customers
      </>
    ),
    tag: "Testimonials",
  };

  const imageTextProps1 = {
    layout: "text-small",
    title: "Stress-Free Order Management",
    titleStyle: "smaller",
    imagePosition: "left",
    description: (
      <p>
        Free yourself from the stress of managing incoming orders across
        multiple tablets with Orders.co’s integration system. Consolidate all of
        your online orders into one easy dashboard. Freeing up your staff and
        counter space.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="order management"
        src="../images/OrdersConsolidation/Stress-Free Order Management.png"
      />
    ),
  };
  const imageTextProps2 = {
    layout: "equal-small",
    title: "A Team Behind You",
    titleStyle: "smaller",
    imagePosition: "right",
    description: (
      <p>
        Access our 24/7 expert support team for all your technical, menu, and
        optimization needs. Our experienced staff can help make your day-to-day
        easier, while increasing your revenue.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="team"
        src="../images/OrdersConsolidation/team.png"
      />
    ),
  };
  const imageTextProps3 = {
    layout: "equal-small",
    title: "15 Minute Onboarding",
    titleStyle: "smaller",
    imagePosition: "left",
    description: (
      <p>
        Schedule a quick 15 minute phone call to give our integration team the
        necessary information, then sit back and let them take over. We provide
        regular updates on the status of your onboarding and will do all the
        heavy lifting for you.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="3rd party integration data"
        src="../images/OrdersConsolidation/integration.png"
      />
    ),
  };

  const questions = [
    {
      question: `How much does setup cost?`,
      answer: `Orders.co setup fee is $100.`,
    },
    {
      question: `How long does it take to set up?`,
      answer: `Orders.co’s integration and setup takes around 1-2 weeks, depending on the current workload.
                For a more detailed estimate, contact a sales representative.`,
    },
    {
      question: `Which delivery platforms are supported?  `,
      answer: `Currently Orders.co supports Grubhub, Postmates, UberEats, and DoorDash. With many more on the way.
                Restaurant partners are also encouraged to request new platform integrations.`,
    },
    {
      question: `What options does Orders.co have for Order Confirmation?`,
      answer: `Orders.co offers both Manual and Auto-Confirmation for incoming orders. Manual
                confirmation is given by default, as it allows for prep-time adjustments at confirmation,
                but a request for Auto can be made to our 24/7 support team at any time.`,
    },
    {
      question: `How can we integrate the ordering site into our existing website?`,
      answer: `An Orders.co ordering site can be easily integrated into your existing website through
                the addition of an “Order Now” button. Customers can click this button and quickly be
                redirected to place their order.`,
    },
  ];

  return (
    <>
      <Seo
        title={"Orders Consolidation"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Top {...topProps} />
        <div
          className="bg-mobile mt-xl-0 pt-xl-0"
          style={{
            backgroundColor: "#F7F7F7",
            marginTop: "-80px",
            paddingTop: "40px",
          }}
        >
          <Features
            items={features}
            classes={["align-items-stretch"]}
          ></Features>
        </div>
        <Testimonials {...testimonialsProps}></Testimonials>
        <div
          className="bg-desktop pb-lg-5"
          style={{
            backgroundImage: `url(${gradientBg})`,
            backgroundSize: `100% 100%`,
            marginBottom: `50px`,
          }}
        >
          <Title>
            <b>All Your Online Orders in One Device</b>
          </Title>
          <ImageText {...imageTextProps1} />
          <ImageText {...imageTextProps2} />
          <ImageText {...imageTextProps3} />
        </div>
        <div
          className="bg-mobile"
          style={{
            background:
              "linear-gradient(180deg, #FFF8ED 0%, rgba(255, 248, 237, 0.5) 100%)",
          }}
        >
          <Integrations scrollToForm={scrollToForm}></Integrations>
          <News items={data.allWpPost} />
          <Faq title="Frequently Asked Questions" items={questions} />
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "orders-consolidation" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
    allWpPost(
      sort: {fields: [date], order: DESC}
      limit: 3
      skip: 0
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "orders-consolidation"}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            gatsbyImage(width: 600,quality: 80)
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;

export default OrdersConsolidationPage;
